.aboutus{
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    min-height: 100vh;
}

.about-image{
    border-radius: 25px 25px 25px 25px;
    width: 100%;
    max-width: 500px;
    height: 100%;
    max-height: 500px ;
}

.about-hr{
    width: 6%;
    margin: 0 auto 0;
    border: 3px solid #d9a95b;
    border-radius: 25px 25px 25px 25px;
}

.about-title{
    font-family: 'Aboreto';
    font-weight: 300;
    transform: translateY(10px);
}

.aboutus-hr{
    width: 23%;
    border:3px solid #d9a95b;
    border-radius: 25px 25px 25px 25px;
}

.aboutus{
    background-image:url('../../assets/themes/AboutusThemes.jpg');
    background-size: cover;
    background-position: center; 
    width: 100%;
    height: 100%;
}

.about-description{
    background-image:url('../../assets/chirstinetaylorartű/Untitled-design-12.png') ;
    background-size: cover;
    background-position: center; 
    width: 100%;
    height: 100%;
}

#card-decs{
    box-shadow: 0px 1px 5px 1px rgba(0,0,0,0.5);
}

.card-desc-img:hover{
    transform: scale(1.04);
    transition: 0.4s ease-in-out;
    border-radius: 25px 25px 25px 25px;
}

.lead{
    font-family: 'Poiret One';
    width:95%;
}

@media screen and (max-width:1240px){
    .about-title{
        text-align: center;
    }

    .aboutus-hr{
        margin: 0 auto 0;
        text-align: center;
    }

    .about-image{
        width: 100%;
        height: auto;
        justify-content: center;
    }
}
@media screen and (max-width: 991px){
    .aboutus{
        background-image:url('../../assets/themes/aboutimg.png');
        background-size: cover;
        background-position: center; 
        width: 100%;
        height: 100%;
    }
    .lead{
        font-family: 'Poiret One';
        width:100%;
    }
}


@media screen and (max-width:768px){
    .aboutus{
        background-image:url('../../assets/themes/aboutthemesphone.jpg');
        background-size: cover;
        background-position: center; 
        width: 100%;
        height: 100%;
    }
    
    .about-description{
        background-image:url('../../assets/themes/aboutphonesthemes.png') ;
        background-size: cover;
        background-position: center; 
        width: 100%;
        height: 100%;
    }

    .about-title{
        padding-top: 50px;
    }

    .lead{
        font-family: 'Poiret One';
        width:100%;
    }
}
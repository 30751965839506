.navbar {
  display: flex;
  position: fixed;
  width: 100%;
  height: 90px;
  justify-content: space-between;
  align-items: center;
  padding: 1rem;
  z-index: 10;
  top: 0;
}

.logoimg{
  width: 80px;
  height: 10px;
}

.navbar-bg{
  background-color: #ffffff;
  transition: .5s;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3);
}

.logo {
  max-width: 50px; /* A logo maximális szélessége */
  height: auto; /* Automatikus magasság */
}

.navbar-logo-img{
  width: 150px;
  height: auto;
}

.social-icons {
  display: flex;
  align-items: center;
}

.social-icon{
  color: #d9a95b;
}

.social-icon:hover{
  color: #DAA520;
}

.nav-links{
  color: #000000;
}

.nav-link{
  font-family: 'Aboreto';
  color: white ;
}

.navbar #nav-link-hover{
  color: #DAA520;
}

#nav-link-hover:hover{
  color: black;
}

.navbar-link:hover{
  color:#DAA520;
}

.menu-icons{
  color: #000000;
}

.social-icons a {
  color: #000000;
  font-size: 1.5rem;
  margin-left: 10px;
}

.nav-item {
  position: relative;
  color: #fff;
  cursor: pointer;
}

.nav-item::after {
  content: '';
  position: absolute;
  bottom: -5px;
  left: 50%;
  width: 60px;
  height: 3px;
  background-color: transparent;
  transform: translateX(-50%);
  transition: background-color 0.3s ease;
  z-index: 1;
}

.nav-item:hover::after {
  background-color: #d9a95b;
  color: #d9a95b;
}

@media screen and (max-width:991px){

  .navbar-collapse {
    padding-top: 26px;
    left: 0;
    right: 0;
    box-shadow: none;
  }

  .navbar{
    height: 100%;
    max-height: 90px;
  }

  .navbar-bg {
    background-color: #ffffff;
    transition: .5s;
  }

  .navbar-nav{
    background-color: #ffffff;
    height: 100%;
    padding: 5px;
    transition: .5s;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3);
  }

  .navbar-link{
    text-align: center;
  }

  .social-icons{
    background-color: #ffffff;
    padding: 0px 10px 10px 10px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-radius: 0px 0px 15px 15px;
    transition: .5s;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.3);
  }

  .nav-item::after {
    content: '';
    position: absolute;
    bottom: -1px;
    left: 50%;
    width: 60px;
    height: 3px;
    background-color: transparent;
    transform: translateX(-50%);
    transition: background-color 0.3s ease;
  }

  .navbar #nav-link-hover{
    color: #000000;
  }
  
  #nav-link-hover:hover{
    color: #DAA520;
  }
  
}
@import url('https://fonts.googleapis.com/css2?family=Poiret+One&family=Roboto:wght@300;400&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Aboreto&family=Poiret+One&display=swap');

:root {
  --primary: #2563eb;
  --background: #f5f8ff;
  --icon-bg:#d9a95b ;
  --icon-hover-bg: #DAA520;
  --gray-bg: #1c1d24 ;
  --brands-bg: #131419 ;
  --color-white: #fff;
}


body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

span p{
  font-family: 'Poiret One';
}

h1 h2 h3 h4 h5{
  font-family: 'Aboreto';
}

.button-unique {
  text-decoration: none;
  display: inline-block;
  padding: .75rem 1.25rem;
  border-radius: 25px;
  color: var(--color-white);
  text-transform: uppercase;
  font-size: 1rem;
  font-family: 'Aboreto';
  letter-spacing: .15rem;
  transition: all .3s;
  position: relative;
  overflow: hidden;
  z-index: 1;
  border: none;
  background-color: var(--icon-bg);
}

.button-unique:after {
  content: '';
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: var(--icon-bg);
  border-radius: 25px;
  z-index: -2;
}

.button-unique:before {
  content: '';
  position: absolute;
  bottom: 0;
  left: 0;
  width: 0%;
  height: 100%;
  background-color: var(--icon-hover-bg);
  transition: all .3s;
  border-radius: 25px;
  z-index: -1;
}

.button-unique:hover {
  color: var(--color-white);
}

.button-unique:hover:before {
  width: 100%;
}
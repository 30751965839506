.slider-container {
    position: relative;
    width: 100%;
    overflow: hidden;
    height: 100%;
    max-height: 100vh;
}

.hero-content{
    transform: translateY(-60px);
}

.slide {
    display: none;
    transition: opacity 1s ease-in-out;
    animation: zoom 4s ease-in-out infinite;
}

.herologo{
    margin: 0 auto 0;
    width: 200px;
    height: 200px;
}

.hero-hr{
    border: 3px solid #d9a95b;
    width: 25%;
    border-radius: 50px 50px 50px 50px;
    margin: 0 auto 0;
}

.hero-description{
    font-family: 'Aboreto';
    font-size: 42px;
}

@keyframes zoom {
    from {
        transform: scale(1);
    }
    to {
        transform: scale(1.1);
    }
}

.slide img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.active {
    display: block;
}

.slider-text {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    text-align: center;
    color: #fff;
    z-index: 1;
}

.slider-text h2,
.slider-text p {
    font-size: 2em;
    margin-bottom: 10px;
    max-width: 80%;
    margin: 0 auto;
}

/* Media query for mobile devices */
@media (max-width: 767px) {
    .slider-container {
        height: 100vh; /* Set height to 100vh on mobile */
    }

    .slide {
        position: relative; /* Adjust position for proper display on mobile */
        height: 100vh; /* Set height to 100vh on mobile */
    }

    .slide img {
        object-position: center top; /* Center the image on the slide */
    }

    .slider-text {
        top: 70%; /* Adjust position for proper display on mobile */
    }
}
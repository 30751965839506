.modal-background {
    width: 100%;
    height: 100%;
    display: flex;
    position: fixed;
    top: 0;
    left: 0;
    color: black;
    justify-content: center;
    align-items: center;
    background-color: rgba(0, 0, 0, 0.7);
    z-index: 1000; 
    margin: 0 auto 0;
    overflow-y: auto;
}

.card-booking{
    width: 50%;
    margin: 0 auto 0;
}

.book-title{
    font-family: "Aboreto";
    font-weight: 300;
    text-decoration:underline;
}


@media screen and (max-width:768px){
    .card-booking{
        width: 100%;
        margin: 0 auto 0;
    }
    .over{
        overflow-y: scroll;
    }    
}
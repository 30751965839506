.company-description{
  background-image: url('../../assets/chirstinetaylorartű/pink5jav.jpeg');
  color:white;
  background-attachment: fixed;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  overflow-x: hidden;
}

.company-about{
  background-image: url('../../assets/companydescription/gold-marble-art-background-diy-luxury-flowing-texture-scaled.jpg');
  color:white;
  background-attachment: fixed;
  background-position: center bottom ;
  background-repeat: no-repeat;
  background-size: cover;
  overflow-x: hidden;
}

.company-border-span{
  border: 10px solid #0d6efd;
  border-radius: 50%;
  height: 100px;
  width: 100px;
  display: flex; /* Ezzel beállítjuk, hogy a tartalom középen legyen. */
  margin: 0 auto 0;
  justify-content: center; /* Vízszintesen középre igazítás. */
  align-items: center; /* Függőlegesen középre igazítás. */
}

.comapny-title{
  font-weight: 400;
  font-family: 'Aboreto';  
}

.company-description-p{
  font-family: 'Poiret One';
  font-weight: 400;
  font-size: 30px;
}

.descrpiton-company{
  font-weight: 300;
  font-family: 'Aboreto';  
}

.company-group{
  text-align: center;
  display: block;
  font-weight: 300;
  font-family: 'Aboreto';  
  align-items: center;
  justify-content: center;
}

.company-picture-img{
  width: 100px;
  border-radius: 50% 50% 50% 50%;
}

.company-written-img{
  width: 100px;
}

@media screen and (max-width:991px){
  .company-description{
    background-image: url('../../assets/chirstinetaylorartű/pink5jav.jpeg');
    color:white;
    background-attachment: scroll;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    position: relative;
  }

  .company-about{
    background-image: url('../../assets/companydescription/gold-marble-art-background-diy-luxury-flowing-texture-scaled.jpg');
    color:white;
    background-attachment: scroll;
    background-position: center bottom ;
    background-repeat: no-repeat;
    background-size: cover;
    overflow-x: hidden;
  }
}

@media screen and (max-width:768px){
  .company-description{
    background-image: url('../../assets/chirstinetaylorartű/pink5jav.jpeg');
    color:white;
    background-attachment: scroll;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    position: relative;
  }

  .company-about{
    background-image: url('../../assets/companydescription/gold-marble-art-background-diy-luxury-flowing-texture-scaled.jpg');
    color:white;
    background-attachment: scroll;
    background-position: center bottom ;
    background-repeat: no-repeat;
    background-size: cover;
    overflow-x: hidden;
  }
}



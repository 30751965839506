.categorylist{
    background-image: url('../../assets/themes/Untitled\ design\ \(16\).png');
    background-size: cover;
    width: 100%;
    height: 100%;
    overflow-x: hidden;
    background-position: center; 
}

.categorylist-title{
    font-family: "Aboreto";
    font-weight: 400 ;
    color: #1a1a1a;
}

.categorylist-paintingsize{
    font-family: "Aboreto";
    font-weight: 400 ;
    color: #1a1a1a;
}

.rentcar-title-category{
    font-family: "Aboreto";
    font-weight: 400 ;
    color: #1a1a1a;
}

.categorylist-description{
    color: #1a1a1a;
    font-family: "Poiret One";
    font-weight: 400;
}
.rentcar-day{
    font-family: "Aboreto";
    font-weight: 700 ;
    color: #1a1a1a;
}

.card-category{
    box-shadow: 0px 1px 8px 1px rgba(0,0,0,0.2);
}

.card-category:hover{
    transform: scale(1.02);
    transition: 0.4s ease-in-out;
}
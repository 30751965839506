
.allfeatured-product{
    overflow-x: hidden;
}
.featuredproducts{
    background-image:url('../../assets/themes/themes.png') ;
    background-size: cover;
    background-position: center; 
    width: 100%;
    height: 100%;
}

.allfeatured-products{
    background-image:url('../../assets/themes/productdetaliimg\ \(2\).png') ;
    background-size: cover;
    background-position: center; 
    width: 100%;
    height: 100%;
}

.categorylist-description{
    color: #1a1a1a;
    font-family: "Poiret One";
    font-weight: 400;
}

.cardetail-information{
    font-family: 'Aboreto';
}

.featured-product-title{
    font-family: 'Aboreto';
}
.featured-paintingsize{
    font-family: 'Aboreto';
    transform: translateY(-10px);
}
.featured-product-description{
    font-family: 'Pointer One';
}

.featured-underline{
    text-decoration: line-through;
    text-decoration-color: #d9a95b;
    text-decoration-thickness: 3px;
}

@media screen and (max-width: 991px) {
    .featuredproducts{
        background-image:url('../../assets/themes/sliderphone.jpg') ;
        background-size: cover;
        background-position: center; 
        width: 100%;
        height: 100%;
    }

    .featured-underline{
        text-decoration: line-through;
        text-decoration-color: #d9a95b;
        text-decoration-thickness: 3px;
    }    
}

@media screen and (max-width: 768px) {
    .featuredproducts{
        background-image:url('../../assets/themes/sliderphone.jpg') ;
        background-size: cover;
        background-position: center; 
        width: 100%;
        height: 100%;
    }

    .featured-underline{
        text-decoration: line-through;
        text-decoration-color: #d9a95b;
        text-decoration-thickness: 3px;
    }    
}
.nextinformation{
    background-image:url('../../assets/themes/neext.png') ;
    background-size: cover;
    background-position: center; 
    width: 100%;
    height: 100%;
    overflow-x: hidden;
}

.nextinformation-hr{
    margin: 0 auto 0;
    border: 3px solid  #d9a95b;
    width: 20%;
    border-radius: 50px 50px 50px 50px;
    transform: translateY(-10px);
    background-color: #d9a95b;
}

.nextinformation-img{
    border-radius: 25px 25px 25px 25px;
    width:100px ;
    height: 150px;
}

.next-title{
    font-family: 'Aboreto' ;
    font-weight: 300;
}

.next-description{
    font-family: 'Poiret One';
    color: black;
    font-size: 20px;
}

@media screen and (max-width: 991px){
    .nextinformation{
        background-image:url('../../assets/themes/nextpagesphone.jpg') ;
        background-size: cover;
        background-position: center; 
        width: 100%;
        height: 100%;
        overflow-x: hidden;
    }
}


@media screen and (max-width: 768px){
    .nextinformation{
        background-image:url('../../assets/themes/nextpagesphone.jpg') ;
        background-size: cover;
        background-position: center; 
        width: 100%;
        height: 100%;
        overflow-x: hidden;
    }
}
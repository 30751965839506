.contact{
    background-image:url('../../assets/themes/Untitled\ design\ \(3\).jpg') ;
    background-size: cover;
    background-position: center; 
    width: 100%;
    min-height: 100vh;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    overflow-x:hidden ;
}

.contact-text-group{
    display: block;
    width: 90%;
    margin: 10px 10px 10px 10px;
    float: left;
}

.contact-title{
    color: #1a1a1a;
    font-weight: 700;
    font-family: 'Poppins';
}

.contact-hr{
    width: 30%;
    border: 3px solid #d9a95b;
    border-radius:50px 50px 50px 50px ;
}

.img-contact {
    border-radius: 25px;  
    box-shadow: 0px 2px 25px 5px rgba(0,0,0,0.5);
    width:450px;
    height: 650px;
    float: right;
}

.contact-title{
    font-family: 'Aboreto';
}

#colol{
    margin: 0px 10px 0px 0px;
}

.contact-description11{
    text-align: justify;
    text-justify: inter-word;
    word-spacing: 1px;
    font-family: "Poiret One";
    float: right;
    font-weight: 400 ;
    color: #565E6D ;
    font-size: 20px;
    letter-spacing: -0.24px;
}

#rowol{
    margin: 0px 10px 0px 10px;
}

@media screen and (max-width:991px){
    .contact{
        background-image:url('../../assets/themes/phone\ \(1\).png') ;
        background-size: cover;
        background-position: center; 
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        overflow-x:hidden ;
    }

    #rowol{
        margin: 0px 0px 0px 0px;
        flex-direction: column;
    }

    .contact-text-group{
        display: block;
        width: 100%;
    }

    .contact-text-group{
        display: block;
        width: 100%;
    }

    .contact-description11{
        width: 100%;
    }

    .img-contact {
        border-radius: 25px;  
        box-shadow: 0px 2px 25px 5px rgba(0,0,0,0.5);
        width:100%;
        height: 100%;
        justify-content: center;
        display: flex;
    }
}

@media screen and (max-width:768px){
    .contact{
        background-image:url('../../assets/themes/Untitleddesign.jpg') ;
        background-size: cover;
        background-position: center; 
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        overflow-x:hidden ;
    }
    #rowol{
        margin: 0px 0px 0px 0px;
    }
    .contact-text-group{
        display: block;
        width: 100%;
    }

    .contact-title{
        padding-top: 5rem;
    }
    
    .img-contact {
        border-radius: 25px;  
        box-shadow: 0px 2px 25px 5px rgba(0,0,0,0.5);
        width:100%;
        height: 100%;
        justify-content: center;
        display: flex;
    }
}
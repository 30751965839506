.cardetail{
    display: flex;
    margin: 0 auto 0;
    justify-content: center;
    background-image:url('../../assets/themes/detail.png') ;
    background-size: cover;
    background-position: center; 
    width: 100%;
    height: 100%;
    margin-top: 90px;
}

.cardetail-description{
    color: #1a1a1a;
    font-family: "Poiret one";
    font-weight: 400;
}

.cardetail-bold-desc{
    color: #1a1a1a;
    font-family: 'Aboreto';
    font-weight: 300;
}
.cardetail-paintingsize{
    color: #1a1a1a;
    font-family: 'Aboreto';
    font-weight: 300;
}

.cardetail-backdown{
    font-family: 'Aboreto';
    font-weight: 300;
}

.cardetail-name{
    color: #1a1a1a;
    font-family: 'Aboreto';
    font-weight: 300;
}

.cardetail-description{
    color: #1a1a1a;
    font-family: "Poiret one";
    font-weight: 400;
}

.cardetail-day-price{
    color: #1a1a1a;
    font-family: 'Aboreto';
    font-weight: 700;
}

#sliders-img{
    height: 100% ;
    width: 100% ;
    max-height: 700px ;
    min-height: 500px ;
}

#sliders-img1{
    height: 100% ;
    width: 100% ;
    max-height: 150px ;
    min-height: 150px ;
    border: 2px solid white;
}

#sliders-img1:hover{
    height: 100% ;
    width: 100% ;
    max-height: 150px ;
    min-height: 150px ;
    border: 2px solid #d9a95b;
    border-radius: 25px 25px 25px 25px;
}

#sliders-img1::before{
    height: 100% ;
    width: 100% ;
    max-height: 150px ;
    min-height: 150px ;
    border: 2px solid #d9a95b;

}

@media screen and (max-width: 1240px){
    #sliders-img{
        height: 100% ;
        width: 100% ;
        max-height: 700px ;
        min-height: 500px ;
    }
    #sliders-img1{
        height: 100% ;
        width: 100% ;
        max-height: 150px ;
        min-height: 150px ;
        border: 2px solid white;
    }

    .cardetail-name{
        color: #1a1a1a;
        font-family: 'Aboreto';
        font-weight: 300;
        padding-top: 10px;
    }

}

@media screen and (max-width: 768px){
    #sliders-img{
        height: 100% ;
        width: 100% ;
    }

    #sliders-img1{
        height: 100% ;
        width: 100% ;
        max-height: 150px ;
        min-height: 150px ;
        border: 2px solid white;
    }

    .cardetail-name{
        color: #1a1a1a;
        font-family: 'Aboreto';
        font-weight: 300;
        padding-top: 10px;
    }
}
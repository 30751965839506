.footer{
    background-color: white;
    display: block;
}

.footer-title{
    font-family: 'Poppins';
    font-weight: 700;
    color: #fff;
}

.footer-policy{
    font-family: 'Rubik';
    font-weight: 400;
}
  
.footer-contact{
    font-family: 'Rubik';
    font-weight: 400;
    color: #817F91;
}

.footer-logo{
    width: 100px;
}

.text-small {
    font-size: 0.9rem;
}

.nav{
    margin: 0 auto 0;
}

.footer-link {
    color: inherit;
    text-decoration: none;
    transition: all 0.3s;
    color: white;
}

.footer-link:hover, .footer-link:focus {
    text-decoration: none;
}


.form-control:focus {
    background: #212529;
}

footer {
    background: #212529;
}
  
.social-icon1{
    color: black;
}

.social-icon1:hover{
    color: #d9a95b ;
}
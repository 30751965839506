.sidebars {
    display: flex;
    position: fixed;
    bottom: 0;
    left: 0; /* opcionálisan: bal oldalra igazítás */
    right: 0; 
}



@media screen and (max-width:1240px) {
    .sidebars{
        height: auto;
        display: block;
        position: absolute;
    }

    
}











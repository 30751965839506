.rentcollection{
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background-image:url('../../assets/chirstinetaylorartű/Untitled-design-12.png') ;
    background-size: cover;
    background-position: center; 
    width: 100%;
}

.rentcoll-title{
    color: #ffffff;
    font-weight: 400;
    font-family: 'Aboreto';
}

.rent-card:hover{
    transform: scale(1.04);
    transition: 0.4s ease-in-out;
}

.cars-image{
    height: 300px;
}

.rentcollection-title{
    color: #1a1a1a;
    font-weight: 400;
    font-family: 'Aboreto';
}

@media screen and (max-width:1240px){
    .rentcollection{
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
    }
}

@media screen and (max-width:768px){
    .rent-card:hover{
        transform: scale(1.01);
        transition: 0.4s ease-in-out;
    }
}

.contactform-group{
    background-image:url('../../assets/themes/contactThemes.png') ;
    background-size: cover;
    background-position: center; 
    width: 100%;
    height: 100%;
    min-height: 100vh;
    justify-content: center;
    align-items: center;
    display: flex;
    overflow-x: hidden;
}

.contact-map{
    display: flex;
}

.contactform-address{
    font-family: 'Aboreto';
    font-weight: 300;
    color: #1a1a1a;
}

.contactform-title-h2{
    font-family: 'Aboreto';
    font-weight: 300;
    color: #1a1a1a;
}

.contactform-title{
    font-family: 'Aboreto';
    font-weight: 300;
    color: #1a1a1a;
}

.contactform-hr{
    background-color: #d9a95b;
    border: none;
    width: 30%;
    height: 10px;
    border-radius: 10px;
    margin: 0;
}

.contactform-addres{
    font-family: 'Aboreto';
    font-weight: 300;
    color: #1a1a1a;
    font-size: 21px;
}

.addres-title{
    font-family: 'Poiret One';
    font-weight: 400;
    color:rgb(129, 127, 145) ;
    font-size:20px;
}

.contactform-lab{
    font-family: 'Poiret One';
    font-weight: 400;
    color:rgb(129, 127, 145) ;
    font-size:20px;
}

.contact-icons{
    color:  #d9a95b;
}

.btn{
    background:  #d9a95b;
}

@media screen and (max-width:991px){
    .contactform-group{
        background-image:url('../../assets/themes/contactthemespohone.jpg') ;
        background-size: cover;
        background-position: center; 
        width: 100%;
        height: 100%;
        min-height: 100vh;
        justify-content: center;
        align-items: center;
        display: flex;
        overflow-x: hidden;
    }

    #contactform-btn{
        margin: 0 auto 0;
        text-align: center;
        padding-bottom: 10px;
    }

    .contactform-contact-group{
        text-align: center;
    }

    .icon-link{
        margin: 0 auto 0;
    }

    .contact-social-media{
        text-align: center;
    }

    .contactform-title-h2{
        text-align:center;        
    }

    .addres{
        padding-top: 30px;
    }

    .contactform-title{
        text-align:center;     
    }

    .contactform-hr{
        margin: 0 auto 0; 
    }

    .btncom{
        margin: 0 auto 0;
        
    }

    .btn-form{
        margin: 0 auto 0;
        text-align: center;
    }
}

@media screen and (max-width:768px){
    .contactform-group{
        background-image:url('../../assets/themes/contactthemespohone.jpg') ;
        background-size: cover;
        background-position: center; 
        width: 100%;
        height: 100%;
        min-height: 100vh;
        justify-content: center;
        align-items: center;
        display: flex;
        overflow-x: hidden;
    }

    #contactform-btn{
        margin: 0 auto 0;
        text-align: center;
        padding-bottom: 10px;
    }

    .contactform-contact-group{
        text-align: center;
    }

    .icon-link{
        margin: 0 auto 0;
    }

    .contact-social-media{
        text-align: center;
    }

    .contactform-title-h2{
        text-align:center;        
    }

    .addres{
        padding-top: 30px;
    }

    .contactform-title{
        text-align:center;     
    }

    .contactform-hr{
        margin: 0 auto 0; 
    }

    .btncom{
        margin: 0 auto 0;
        
    }

    .btn-form{
        margin: 0 auto 0;
        text-align: center;
    }
}
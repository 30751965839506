@import url("https://fonts.googleapis.com/css2?family=Russo+One&display=swap");

.loading{
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    overflow-x: hidden;
}

.wrapper{
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #FFFFFF
}



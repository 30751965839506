.breadcrumb-component {
    height: 50vh;
    width: 100%;
    position: relative;
}

.breadcrumb-component::before {
    background-image: url('../../assets/breakdumb/rough-gray-marble-texture-with-streaks-scaled.jpg');
    background-size: cover;
    background-position: center; 
    background-repeat: no-repeat;
    content: "";
    position: absolute;
    height: 100%;
    width: 100%;
    top: 0;
    left: 0;
    z-index: -1;
}

.breadcrumb-card {
    display: flex;
    align-items: center;
    justify-content: center;
}

.breadcrumb-title {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    display: flex;
    align-items: center;
    z-index: 1;
    color: #ffffff;
    font-weight: 400;
    font-family: 'Aboreto';
}

.breadcrumb {
    position: absolute;
    top: 60%;
    left: 50%;
    transform: translate(-50%, -50%);
    display: flex;
    justify-content: center;
    align-items: baseline;
    z-index: 1;
}

.breadcrumb-list {
    color: #ffffff;
    font-weight: 400;
    font-family: 'Aboreto';
}

.breadcrumb-link {
    text-decoration: none;
    color: #ffffff;
    font-weight: 400;
    font-family: 'Aboreto';
}



@media screen and (max-width: 768px) {
    .breadcrumb-component::before {
        height: 50vh;
        background-attachment: scroll;
        background-position: center bottom ;
        background-repeat: no-repeat;
        background-size: cover;
        overflow-x: hidden;
    }
}


.rent-car{
    background-image:url('../../assets/themes/Untitled\ design\ \(1\).jpg') ;
    background-size: cover;
    background-position: center; 
    width: 100%;
    height: 100%;
    min-height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: 100vh;
    overflow-x:hidden ;
}

.rentcar-description{
    text-align: justify;
    text-justify: inter-word;
    font-family: "Poiret One";
    font-weight: 400 ;
    font-size: 20px;
    color: #565E6D ;
}

.img-important{
    box-shadow: 0px 2px 26px 5px rgba(0,0,0,0.5);
}

.rent-car-title{
    color: #1a1a1a;
    font-family: 'Aboreto';
}

.rentcar-hr{
    width: 30%;
    border: 3px solid #d9a95b;
    border-radius:50px 50px 50px 50px ;
}

#colol1{
    display: block;
    width: 90%;
    float: right;
}

#rowol1{
    margin: 0px 10px 0px 10px;
}
@media screen and (max-width:991px){
    #rowol1{
        margin: 0px 0px 0px 0px;
    }
    #colol1{
        display: block;
        width: 100%;
        margin: 0 auto 0;
    }
    .rent-carddesh{
        display: block;
        width: 100%;
    }
    .rent-car{
        display: flex;
        align-items: center;
        justify-content: center;
        background-image:url('../../assets/themes/themesunder.png') ;
        background-size: cover;
        background-position: center; 
        width: 100%;
        height: 100%;
        overflow-x: hidden;
    }
}

@media screen and (max-width:768px){
    #rowol1{
        margin: 0px 0px 0px 0px;
    }
    #colol1{
        display: block;
        width: 100%;
        margin: 0 auto 0;
    }
    .rent-carddesh{
        display: block;
        width: 100%;
    }
    #rowol{
        margin: 0px 0px 0px 0px;
    }
    .rent-car{
        display: flex;
        align-items: center;
        justify-content: center;
        background-image:url('../../assets/themes/themesunder.png') ;
        background-size: cover;
        background-position: center; 
        width: 100%;
        height: 100%;
        min-height: 100%;
        overflow-x: hidden;
    }
    .rent-car-title{
        text-align: center;

    }

    .rentcar-description{
        margin-bottom: 8rem;
    }

    .rentcar-hr{
        margin: 0 auto 0;
    }
}